
const policies = {
  "financial_create": ["/api/v1/financial/pay/:condominium_id", "POST"],
  "financial_list": ["/api/v1/financial/pays/:condominium_id", "GET"],
  "financial_delete": ["/api/v1/financial/pay/:condominium_id/:document_id", "DELETE"],
  "financial_update": ["/api/v1/financial/pay/:condominium_id/:document_id/:action", "PUT"],
  "financial_extract": ["/api/v1/financial/budget/:condominium_id/:id/ladgers", "GET"],
}

const accounts_payable = [
  {    
    path: "/accounts-payable",
    component: () => import("@/layouts/full-layout/Layout"),
    children: [
      {
        name:"PayableList",
        path: "list",
        component: () => import("@/views/accounts-payable/list"),
        meta: {
          permissions: [
            policies["financial_list"]
          ]
        }
      },
      {
        name:"PayableAdd",
        path: ":name",
        component: () => import("@/views/accounts-payable/form"),
        meta: {
          permissions: [
            policies["financial_create"],
          ]
        }
      },
      {
        name:"PayableLot",
        path: ":name",
        component: () => import("@/views/accounts-payable/form"),
        meta: {
          permissions: [
            policies["financial_create"],
          ]
        }
      },
      {
        name:"PayableUpdate",
        path: ":name/:id",
        component: () => import("@/views/accounts-payable/form"),
        meta: {
          permissions: [
            policies["financial_update"],
          ]
        }
      },
    ]
  }
]

export default accounts_payable;