
const policies = {
  
  "charts_action_by_year":   ["/api/v1/financial/pay_receive/:condominium_id/charts/:name", "GET"],
  "budget_list": ["/api/v1/financial/budgets/:condominium_id", "GET"],
  
}

const home = [
  {
    path: "/",
    redirect: "dashboards/default",
    component: () => import("@/layouts/full-layout/Layout"),
    meta: {
      auth: false,
    },
    children: [
      {
        name: "403Forbidden",
        path: "403",
        component: () => import("@/views/403Forbidden"),
        meta: {
          auth: false
        }
      },
      {
        name: "Default",
        path: "dashboards/default",
        component: () => import("@/views/dashboards/default"),
        meta: {
          auth: true,
          permissions: [
            policies["charts_action_by_year", "budget_list"]
          ]
        }
      }
    ]
  },
  {
    name: "404NotFound",
    path: "*",
    component: () => import("@/views/404PageNotFound"),
    meta: {
      auth: false
    }
  }
]

export default home;