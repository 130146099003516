'strict mode'

import axios from 'axios';
import utils from './utils';

import {store} from '@/store';

const list = () => {
    return new Promise((resolve, reject) => {
        axios.get(`/v1/authorizations/${store.user.selected_condominium.key}`, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const group_list = () => {
    return new Promise((resolve, reject) => {
        axios.get(`/v1/authorization/groups/${store.user.selected_condominium.key}`, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const resources = () => {
    return new Promise((resolve, reject) => {
        axios.get(`/v1/authorization/resources/${store.user.selected_condominium.key}`, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const create = (authorization) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/authorization/${store.user.selected_condominium.key}`, authorization, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const group_create = (authorization) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/authorization/groups/${store.user.selected_condominium.key}`, authorization, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const remove = (authorization) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/v1/authorization/${store.user.selected_condominium.key}`, {
            headers: { Authorization: `Bearer ${store.access_token}` },
            data: authorization
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const group_remove = (authorization) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/v1/authorization/groups/${store.user.selected_condominium.key}`, {
            headers: { Authorization: `Bearer ${store.access_token}` },
            data: authorization
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const user_grant = (authorization,user) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/authorization/users/${store.user.selected_condominium.key}/${user}`, authorization, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            console.error(error)
            reject(utils.get_error(error));
        });
    });
}

const user_revoke = (authorization,user) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/v1/authorization/users/${store.user.selected_condominium.key}/`+user, {
            headers: { Authorization: `Bearer ${store.access_token}` },
            data: authorization
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

export default {
    list,
    resources,
    create,
    remove,
    group_list,
    group_create,
    group_remove,
    user_grant,
    user_revoke
}