
const policies = {
  "people_list":   ["/api/v1/condominium/people/:condominium_id", "GET"],
  "people_create": ["/api/v1/condominium/people/:condominium_id", "POST"],
  "people_update": ["/api/v1/condominium/block/:condominium_id/:id", "PUT"],
  "people_remove": ["/api/v1/condominium/block/:condominium_id/:id", "DELETE"],
}

const block = [
  {
    path: "/people",
    component: () => import("@/layouts/full-layout/Layout"),
    children: [
      {
        name:"PeopleList",
        path: "list",
        component: () => import("@/views/people/list"),
        meta: {
          permissions: [
            policies["people_list"]
          ]
        }
      },
      {
        name:"PeopleNew",
        path: ":name",
        component: () => import("@/views/people/form"),
        meta: {
          permissions: [
            policies["people_create"]
          ]
        }
      },
      {
        name:"PeopleUpdate",
        path: ":name/:id",
        component: () => import("@/views/people/form"),
        meta: {
          permissions: [
            policies["people_update"]
          ]
        }
      },
    ]
  },
]

export default block;