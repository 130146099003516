
const policies = {
  
  "blocks_list":   ["/api/v1/condominium/blocks/:condominium_id", "GET"],
  
}

const floor = [
  {
    path: "/floors",
    component: () => import("@/layouts/full-layout/Layout"),
    children: [
    {
      name:"FloorsList",
      path: ":id",
      component: () => import("@/views/floors/list"),
      meta: {
        permissions: [
          policies["blocks_list"]
        ]
      }
    },
    ]
  },
]

export default floor;