
import i18n from "@/plugins/vuei18n"

const get_error = (error) => {
    if("response" in error) {
        if(error.response != undefined) {
            const data = error.response.data
            const detail = data.detail
            let message = "";
            if(data.type == "validation"){
                for(let [i, err] of detail.entries()) {
                    let loc = err.loc.filter(item => item != "body")
                    loc = loc.map(item => i18n.t("error.fields." + item))
                    loc = loc.join(', ')
                    let msg = i18n.t("error." + err.msg)
                    if( i > 0 ) { message += " "}
                    message += `"${loc}" ${msg}.`
                }
            } else if(data.type == "api") {
                let loc = ""
                for(let [i, err] of detail.entries()) {
                    if( i > 0 ) { message += " "; loc += ", "}
                    loc += err.loc[0]
                    message += err.msg
                }
                message += ` (${loc})`
            } else {
                message = i18n.t("error." + detail[0].msg) + `. (${detail[0].loc[0]})`
            }
            return {
                "message": message,
                "error": data
            }
        } else {
            console.error(error["message"])
            console.error(error["stack"])
            return {
                "message": i18n.t(error["message"]),
                "error": error["stack"]
            }
        }
    } else if("message" in error) {
        console.error(error["message"])
        console.error(error["stack"])
        return {
            "message": i18n.t(error["message"]),
            "error": error["stack"]
        }
    }
    return error
}

export default {
    get_error
}