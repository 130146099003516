import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@/scss/vuetify/overrides.scss";
Vue.use(Vuetify);

const theme = {
  primary: "#384e77", // change header color from here || "#1e88e6", "#21c1d6", "#fc4b6c", "#563dea", "#9C27b0", "#ff9800"
  secondary: "#388E3C",
  tertiary: "#0e79b2" ,
  accent: "#E65100",

  info: "#384e77",
  success: "#1DE9B6",
  error: "#FF1744",
  danger: "#D50000",
  
  default: "#B71C1C",
};

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
    dark: false, // If you want to set dark theme then dark:true else set to false
  },
  rtl: false, // If you want to set rtl theme then rtl:true else set to false
});
