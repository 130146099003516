'strict mode'

import axios from 'axios';
import utils from './utils';

import {store} from '@/store';

const attach = (file, id, comment_id) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/ticket/comment/${store.user.selected_condominium.key}/${id}/${comment_id}/attach`, file, {
            headers: { Authorization: `Bearer ${store.access_token}`, "Content-Type": "multipart/form-data" }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const unattach = (fileinfo_id, id, comment_id) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/ticket/comment/${store.user.selected_condominium.key}/${id}/${comment_id}/unattach/${fileinfo_id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const seeAttach = (fileinfo_id, id, comment_id) => {
    return new Promise((resolve, reject) => {
        axios.get(`/v1/ticket/comment/${store.user.selected_condominium.key}/${id}/${comment_id}/attached/${fileinfo_id}`, {
            headers: { Authorization: `Bearer ${store.access_token}` },
            responseType: "blob"
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const create = (data) => {
    return new Promise ((resolve, reject) => {
        axios.post(`/v1/condominium/people/${store.user.selected_condominium.key}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` },
            data: data
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const update = (data, id) => {
    return new Promise ((resolve, reject) => {
        axios.put(`/v1/condominium/people/${store.user.selected_condominium.key}/${id}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const remove = (id) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/condominium/people/${store.user.selected_condominium.key}/${id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const list = () => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/condominium/people/${store.user.selected_condominium.key}?`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const associate = (id, unit_id) => {
    return new Promise ((resolve, reject) => {
        axios.post(`/v1/condominium/people/${store.user.selected_condominium.key}/${id}/unit/${unit_id}`, "", {
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const disassociate = (id, unit_id) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/condominium/people/${store.user.selected_condominium.key}/${id}/unit/${unit_id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}


export default {
    attach,
    unattach,
    seeAttach,
    create,
    update,
    remove,
    list,
    associate,
    disassociate,
}