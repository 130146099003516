'strict mode'

import axios from 'axios';
import utils from './utils';

import {store} from '@/store';

const create = (data, commonid) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/condominium/reservable/${store.user.selected_condominium.key}/${commonid}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const list = () => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/condominium/reservables/${store.user.selected_condominium.key}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const possibleAreas = () => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/condominium/commonareas/${store.user.selected_condominium.key}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data.filter(item=>item.reservable==true));
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const update = (data, commonid, id) => {
    return new Promise ((resolve, reject) => {
        axios.put(`/v1/condominium/reservable/${store.user.selected_condominium.key}/${commonid}/${id}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const remove = (commonid, id) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/condominium/reservable/${store.user.selected_condominium.key}/${commonid}/${id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}



export default {
    create,
    list,
    possibleAreas,
    update,
    remove,
}