
const policies = {
  
  "user_view":   ["/api/v1/me/:condominium_id", "GET"],
  "user_list":   ["/api/v1/users/:condominium_id", "GET"],
  "user_create": ["/api/v1/me/:condominium_id", "POST"],
  "user_edit":   ["/api/v1/me/:condominium_id", "POST"],
  "user_delete": ["/api/v1/user/refuse/:condominium_id", "DELETE"],
  
}


const user = [
  {
    path: "/user",
    component: () => import("@/layouts/full-layout/Layout"),
    children: [
      {
        name:"Look",
        path: "look",
        component: () => import("@/views/user/look"),
        meta: {
          permissions: [
            policies["user_view"]
          ]
        }
      },
      {
        name:"Edit",
        path: "edit",
        component: () => import("@/views/user/edit"),
        meta: {
          permissions: [
            policies["user_edit"]
          ]
        }
      },
    ]
  },
]

export default user;