'strict mode'

import axios from 'axios';
import utils from './utils';

import {store} from '@/store';


const list = () => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/subscriptions/${store.user.selected_condominium.key}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const extend = (id) => {
    return new Promise ((resolve, reject) => {
        axios.put(`/v1/subscription/${store.user.selected_condominium.key}/extend/${id}`, "", {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}


export default {
    list,
    extend,
}