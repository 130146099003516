
const policies = {
  
  "approval_list": ["/api/v1/administration/approvals/:condominium_id", "GET"],
  "approval_new": ["/api/v1/administration/approval/:condominium_id", "POST"],
  "approval_remove": ["/api/v1/administration/approval/:condominium_id/:id", "DELETE"],
  "approval_update": ["/api/v1/administration/approval/:condominium_id/:id", "PUT"],
  "approval_associate": ["/api/v1/administration/approval/:condominium_id/:id/user/:key", "POST"],   
  
}

const approval = [
  {
    path: "/approval",
    component: () => import("@/layouts/full-layout/Layout"),
    children: [
      {
        name:"ApprovalList",
        path: "list",
        component: () => import("@/views/approval/list"),
        meta: {
          permissions: [
            policies["approval_list"]
          ]
        }
      },
      {
        name:"ApprovalForm",
        path: ":name",
        component: () => import("@/views/approval/form"),
        meta: {
          permissions: [
            policies["approval_list"]
          ]
        }
      },
      {
        name:"ApprovalUpdateTitle",
        path: ":name/:id",
        component: () => import("@/views/approval/form"),
        meta: {
          permissions: [
            policies["approval_list"]
          ]
        }
      },
      {
        name:"ApprovalAssociate",
        path: ":name/:id",
        component: () => import("@/views/approval/form"),
        meta: {
          permissions: [
            policies["approval_associate"]
          ]
        }
      },
    ]
  },
]

export default approval;