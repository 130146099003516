'strict mode'

import axios from 'axios';
import utils from './utils';

import ncondo_v1 from "@/api/ncondo/v1";
import {store} from '@/store';

const attachBudgetArchive = (file, id) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/financial/budget/${store.user.selected_condominium.key}/${id}/attach`, file, {
            headers: { Authorization: `Bearer ${store.access_token}`, "Content-Type": "multipart/form-data" }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const unattachBudgetArchive = (fileinfo_id, id) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/financial/budget/${store.user.selected_condominium.key}/${id}/unattach/${fileinfo_id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const seeBudgetAttachment = (id, fileinfo_id) => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/financial/budget/${store.user.selected_condominium.key}/${id}/attached/${fileinfo_id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` },
            responseType: "blob"
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const attachQuoteArchive = (file, id) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/financial/quote/${store.user.selected_condominium.key}/${id}/attach`, file, {
            headers: { Authorization: `Bearer ${store.access_token}`, "Content-Type": "multipart/form-data" }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const unattachQuoteArchive = (fileinfo_id, id) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/financial/quote/${store.user.selected_condominium.key}/${id}/unattach/${fileinfo_id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const seeQuoteAttachment = (id, fileinfo_id) => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/financial/quote/${store.user.selected_condominium.key}/${id}/attached/${fileinfo_id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` },
            responseType: "blob"
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const createPurchase = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/financial/purchase/${store.user.selected_condominium.key}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const createQuote = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/financial/quote/${store.user.selected_condominium.key}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const createReceive = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/financial/budgetrecord/${store.user.selected_condominium.key}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const createPayable = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/financial/budgetrecord/${store.user.selected_condominium.key}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const createAnnual = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/financial/budget/${store.user.selected_condominium.key}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` },
            data:data
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const createBulk = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/financial/budgetrecord/bulk/${store.user.selected_condominium.key}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const updatePurchase = (id, data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/v1/financial/purchase/${store.user.selected_condominium.key}/${id}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const updateQuote = (id, data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/v1/financial/quote/${store.user.selected_condominium.key}/${id}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const updateStatus = (id, status) => {
    return new Promise((resolve, reject) => {
        axios.put(`/v1/financial/budgetrecord/${store.user.selected_condominium.key}/${id}/${status}`, {}, {
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const updatePayReceive = (id, data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/v1/financial/budgetrecord/${store.user.selected_condominium.key}/${id}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const updateBudgetAnnual = (id, data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/v1/financial/budget/${store.user.selected_condominium.key}/${id}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const listCategories = () => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/financial/categories/${store.user.selected_condominium.key}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const listReceive = (page=1, size=10, type="R", sort_by="date", sort_order="ASC", params="") => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/financial/budgetrecords/${store.user.selected_condominium.key}?page=${page}&size=${size}&type=${type}&sort_by=${sort_by}&sort_order=${sort_order}${params}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const listPayable = (page=1, size=10, type="P", sort_by="date", sort_order="ASC") => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/financial/budgetrecords/${store.user.selected_condominium.key}?page=${page}&size=${size}&type=${type}&sort_by=${sort_by}&sort_order=${sort_order}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const listBudget = (page=1, size=10, type="B", sort_by="date", sort_order="ASC") => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/financial/budgetrecords/${store.user.selected_condominium.key}?page=${page}&size=${size}&type=${type}&sort_by=${sort_by}&sort_order=${sort_order}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const listPurchases = () => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/financial/purchases/${store.user.selected_condominium.key}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const getSpecificPurchase = (id) => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/financial/purchase/${store.user.selected_condominium.key}/${id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const listQuotes = () => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/financial/quotes/${store.user.selected_condominium.key}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const listBudgetAnnual = () => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/financial/budgets/${store.user.selected_condominium.key}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const chartActionByYear = (year=2021) => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/financial/receive/${store.user.selected_condominium.key}/charts/action_by_year?year=${year}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const seeStatements = (id) => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/financial/budget/${store.user.selected_condominium.key}/${id}/statements`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const budgetApproval= (id, action, commentary="") => {
    return new Promise((resolve, reject) => {
        axios.put(`/v1/financial/budget/${store.user.selected_condominium.key}/${id}/approval/${action}`, commentary, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            ncondo_v1.notification.sync()
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const purchaseApproval= (id, action) => {
    return new Promise((resolve, reject) => {
        axios.put(`/v1/financial/purchase/${store.user.selected_condominium.key}/${id}/approval/${action}`, '', {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
} 

const purchaseActionWithQuote= (action, id, qid, commentary) => {
    return new Promise((resolve, reject) => {
        axios.put(`/v1/financial/purchase/${store.user.selected_condominium.key}/${id}/approval/${action}/${qid}`, commentary, {
            headers: { Authorization: `Bearer ${store.access_token}` },
            data:commentary
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const purchaseOrderUpdate= (id, state ) => {
    return new Promise((resolve, reject) => {
        axios.put(`/v1/financial/purchase/${store.user.selected_condominium.key}/${id}/order/${state}`,"", {
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const purchaseActionWithoutQuote= (action, id, commentary) => {
    return new Promise((resolve, reject) => {
        axios.put(`/v1/financial/purchase/${store.user.selected_condominium.key}/${id}/approval/${action}`, commentary, {
            headers: { Authorization: `Bearer ${store.access_token}` },
            data:commentary
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const remove = (id) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/financial/budgetrecord/${store.user.selected_condominium.key}/${id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const removePurchase = (id) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/financial/purchase/${store.user.selected_condominium.key}/${id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` },
            data: id
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const removeQuote = (id) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/financial/quote/${store.user.selected_condominium.key}/${id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` },
            data: id
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const removeBudgetAnnual = (value) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/financial/budget/${store.user.selected_condominium.key}/${value}`,{
            headers: { Authorization: `Bearer ${store.access_token}` },
            data: value
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const receiveGetYear = () => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/financial/budgetrecord/${store.user.selected_condominium.key}/years?type=R`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

export default {

    attachBudgetArchive,
    unattachBudgetArchive,
    seeBudgetAttachment,
    attachQuoteArchive,
    unattachQuoteArchive,
    seeQuoteAttachment,
    createPurchase,
    createQuote,
    createReceive,
    createPayable,
    createAnnual,
    createBulk,
    updatePurchase,
    updateQuote,
    updateStatus,
    updatePayReceive,
    updateBudgetAnnual,
    listPurchases,
    getSpecificPurchase,
    listQuotes,
    listCategories,
    listReceive,
    listPayable,
    listBudget,
    listBudgetAnnual,
    chartActionByYear,
    seeStatements,
    budgetApproval,
    purchaseApproval,
    purchaseActionWithQuote,
    purchaseActionWithoutQuote,
    purchaseOrderUpdate,
    remove,
    removePurchase,
    removeQuote,
    removeBudgetAnnual,
    receiveGetYear
}