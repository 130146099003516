
const policies = {
  "blocks_list":   ["/api/v1/condominium/blocks/:condominium_id", "GET"],
  "blocks_create": ["/api/v1/condominium/block/:condominium_id", "POST"],
  "blocks_update": ["/api/v1/condominium/block/:condominium_id/:id", "PUT"],
  "blocks_remove": ["/api/v1/condominium/block/:condominium_id/:id", "DELETE"],
}

const block = [
  {
    path: "/blocks",
    component: () => import("@/layouts/full-layout/Layout"),
    children: [
      {
        name:"BlocksList",
        path: "list",
        component: () => import("@/views/blocks/list"),
        meta: {
          permissions: [
            policies["blocks_list"]
          ]
        }
      },
      {
        name:"BlocksNew",
        path: ":name",
        component: () => import("@/views/blocks/form"),
        meta: {
          permissions: [
            policies["blocks_create"]
          ]
        }
      },
      {
        name:"BlocksUpdate",
        path: ":name/:id",
        component: () => import("@/views/blocks/form"),
        meta: {
          permissions: [
            policies["blocks_update"]
          ]
        }
      },
    ]
  },
]

export default block;