
const policies = {
  
  "user_view":   ["/api/v1/me/:condominium_id", "GET"],
  "user_list":   ["/api/v1/users/:condominium_id", "GET"],
  "user_create": ["/api/v1/me/:condominium_id", "POST"],
  "user_edit":   ["/api/v1/me/:condominium_id", "POST"],
  "user_delete": ["/api/v1/user/refuse/:condominium_id", "DELETE"],

  "authorization_list": ["/api/v1/authorizations/:condominium_id", "GET"],
  "authorization_create": ["/api/v1/authorization/:condominium_id", "POST"],
  "authorization_delete": ["/api/v1/authorization/:condominium_id", "DELETE"],

  "subscription_list": ["/api/v1/subscriptions/:condominium_id", "GET"],
  
  "authorization_groups_list": ["/api/v1/authorization/groups/:condominium_id", "GET"],
  "authorization_groups_create": ["/api/v1/authorization/groups/:condominium_id", "POST"],
  "authorization_groups_delete": ["/api/v1/authorization/groups/:condominium_id", "DELETE"],
  
  "authorization_resources_list": ["/api/v1/authorization/resources/:condominium_id", "GET"],
  
  "authorization_user_create": ["/api/v1/authorization/users/:condominium_id/:user_key", "POST"],
  "authorization_user_delete": ["/api/v1/authorization/users/:condominium_id/:user_key", "DELETE"],

  "enumerations_list": ["/api/v1/administration/enumerations/:condominium_id", "GET"],
  "enumerations_create": ["/api/v1/administration/enumeration/:condominium_id", "POST"],
  "enumerations_update": ["/api/v1/administration/enumeration/:condominium_id/:id", "PUT"],
  "enumerations_delete": ["/api/v1/administration/enumeration/:condominium_id/:id", "DELETE"],

}

const admin = [
  {
    path: "/admin",
    component: () => import("@/layouts/full-layout/Layout"),
    children: [
      {
        name: "Authorization",
        path: "authorization",
        component:{ render(c) {return c("router-view")} },
        children: [
          {
            name: "AuthorizationList",
            path: "list",
            component: () => import("@/views/admin/authorization/list"),
            meta: {
              permissions: [
                policies["authorization_list"]
              ]
            }
          },
          {
            name: "AuthorizationCreateForm",
            path: "form",
            component: () => import("@/views/admin/authorization/form"),
            meta: {
              permissions: [
                policies["authorization_create"]
              ]
            }
          },
          {
            name: "AuthorizationEditForm",
            path: "form/:name",
            component: () => import("@/views/admin/authorization/form"),
            meta: {
              permissions: [
                policies["authorization_create"]
              ]
            }
          },
        ]
      },
      {
        name: "Subscription",
        path: "subscription",
        component:{ render(c) {return c("router-view")} },
        children: [
          {
            name: "SubscriptionList",
            path: "list",
            component: () => import("@/views/admin/subscription/list"),
            meta: {
              permissions: [
                policies["subscription_list"]
              ]
            }
          },
        ]
      },
      {
        name: "AuthorizationGroup",
        path: "authorization_group",
        component:{ render(c) {return c("router-view")} },
        children: [
          {
            name: "AuthorizationGroupList",
            path: "list",
            component: () => import("@/views/admin/authorization_group/list"),
            meta: {
              permissions: [
                policies["authorization_groups_list"]
              ]
            }
          },
          {
            name: "AuthorizationGroupCreateForm",
            path: "form",
            component: () => import("@/views/admin/authorization_group/form"),
            meta: {
              permissions: [
                policies["authorization_groups_create"]
              ]
            }
          },
          {
            name: "AuthorizationGroupEditForm",
            path: "form/:name",
            component: () => import("@/views/admin/authorization_group/form"),
            meta: {
              permissions: [
                policies["authorization_groups_create"]
              ]
            }
          },
        ]
      },
      {
        name: "Users",
        path: "users",
        component:{ render(c) {return c("router-view")} },
        children: [
          {
            name: "UserList",
            path: "list",
            component: () => import("@/views/admin/user/list"),
            meta: {
              permissions: [
                policies["user_list"]
              ]
            }
          },
          {
            name: "UserForm",
            path: "form",
            component: () => import("@/views/admin/user/form"),
            meta: {
              permissions: [
                policies["user_create", "user_list"]
              ]
            }
          },
          {
            name: "UserEditForm",
            path: "form/:key",
            component: () => import("@/views/admin/user/form_authorization"),
            meta: {
              permissions: [
                policies["authorization_user_delete", "authorization_user_create"]
              ]
            }
          },
        ]
      },
      {
        name: "Enumeration",
        path: "enumeration",
        component:{ render(c) {return c("router-view")} },
        children: [
          {
            name: "EnumerationList",
            path: "list",
            component: () => import("@/views/admin/enumeration/list"),
            meta: {
              permissions: [
                policies["enumerations_list"]
              ]
            }
          }
        ]
      },
    ]
  },
]

export default admin;