'strict mode'

export default {
    sign: require('./sign').default,
    condominium: require('./condominium').default,
    me: require('./me').default,
    authorization: require('./authorization').default,
    blocks: require('./blocks').default,
    floors: require('./floors').default,
    people: require('./people').default,
    approval: require('./approval').default,
    suppliers: require('./suppliers').default,
    contracts: require('./contracts').default,
    units: require('./units').default,
    unit_extension: require('./unit_extension').default,
    enumerations: require('./enumerations').default,
    financial: require('./financial').default,
    summary: require('./summary').default,
    receivable: require('./receivable').default,
    receivableOrder: require('./receivableOrder').default,
    payable: require('./payable').default,
    user: require('./user').default,
    ticket: require('./ticket').default,
    ticket_comment: require('./ticket_comment').default,
    commonAreas: require('./commonAreas').default,
    reservable: require('./reservable').default,
    reservation: require('./reservation').default,
    subscription: require('./subscription').default,
    notification: require('./notification').default
}