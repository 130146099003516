import Vue from "vue";
import Router from "vue-router";

import goTo from "vuetify/es5/services/goto";

import { store } from "@/store";
import ncondo_v1 from "@/api/ncondo/v1/index";
import NProgress from "nprogress";

// BEGIN - this session for import route file and add in routes

import home from "./home";
import sign from "./sign";
import block from "./block";
import floor from "./floor";
import unit from "./unit";
import accounts_receivable from "./accounts_receivable";
import accounts_payable from "./accounts_payable";
import budget from "./budget";
import purchase from "./purchase";
import supplier from "./supplier";
import contract from "./contract";
import approval from "./approval";
import user from "./user";
import tickets from "./tickets";
import commonAreas from "./commonAreas"
import reservable from "./reservable"
import reservation from "./reservation"
import people from "./people";
import admin from "./admin";
import icons from "./icons";

const routes = [
    home,
    user,
    sign,
    block,
    commonAreas,
    reservable,
    reservation,
    floor,
    unit,
    accounts_receivable,
    accounts_payable,
    budget,
    purchase,
    supplier,
    contract,
    approval,
    tickets,
    people,
    admin,
    icons
]

// END - this session for import route file and add in routes

Vue.use(Router);

let _routes = []
for(let route of routes) {
    _routes = _routes.concat(route)
}

const _has_policies_in_route = (permissions) => {
  if(store.user.condominum == null) {
      for(let permz of permissions) {
          if(
              permz[0] == "/api/v1/condominium/condominium" &&
              permz[1] == "POST"
          ) {
              return true;
          }
      }
  }
  for(let permz of permissions) {
      for(let authz of store.user.authorizations) {
          if(permz[0] == authz.resource && permz[1] == authz.method) {
              return true
          }
      }  
  }
  return false;
}

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: _routes
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  const auth = to.matched.some(record => {
    if(record.meta.auth == undefined) return true;
    return record.meta.auth
  });
  ncondo_v1.sign.is_authenticated()
  .then(() => {
    // Check is user have access the path to page
    const permit = to.matched.some(record => {
      if(! auth) return true
      if(record.meta.permissions == undefined) return false;
      return _has_policies_in_route(record.meta.permissions);
    })
    if(permit && (to.name == "404NotFound")) {
        next();
    } else if(! store.user.is_active && to.path != '/sign/confirm') {
      next('/sign/confirm');
    } else if(to.path == '/sign/confirm' || to.path == '/sign/invited') {
      next();
    } else if(store.user.selected_condominium == null && store.user.condominiums.length == 1) {
      ncondo_v1.sign.is_authenticated(store.user.condominiums[0].key)
      .then(() => {
        next({ name: 'Default' })
      }).catch((error) => {
        console.error(error)
        if(auth) {
          next('/sign/in');
        } else {
          next();
        }
      })
    } else if(store.user.selected_condominium == null && store.user.condominiums.length > 1 && to.path != '/sign/choose-condominium') {
      // If not have choose one condominium and have more one condominiums
      next('/sign/choose-condominium');
    } else if(store.user.selected_condominium == null && to.path != '/sign/condominium' && to.path != '/sign/choose-condominium') {
      // If nor condominuim redirect to add condominium name
      next('/sign/condominium');
    } else if(to.path.includes("/sign/") && to.path != '/sign/condominium' && to.path != '/sign/choose-condominium') {
      // if is authenticated and access the signs pages redirect to home
      next('/');
    } else if(! permit && (to.path != '/403' && to.name != "404NotFound")) {
      next('/403?t=_' + new Date().getTime());
    } else {
      // show the page
      next();
    }
  })
  .catch((error) => {
    if(error) console.error(error)
    if(auth) {
      next('/sign/in');
    } else {
      next();
    }
  });
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
