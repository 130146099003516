'strict mode'

import axios from 'axios';
import utils from './utils';

import {store} from '@/store';

const lookAtMe = () => {
    return new Promise((resolve, reject) => {
        let condominium_key = null
        if(store.user.condominium) {
            condominium_key = store.user.selected_condominium.key
        } else {
            condominium_key = "_"
        }
        axios.get(`/v1/me/${condominium_key}`,  {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const editMe = (user) =>{
    return new Promise((resolve, reject) => {
        axios.post(`/v1/me/${store.user.selected_condominium.key}`, user,  {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

export default {
    lookAtMe,
    editMe,
}