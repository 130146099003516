
const policies = {
  "condominium_create": ["/api/v1/condominium/condominium", "POST"],
  "condominium_view":   ["/api/v1/condominium/condominium", "GET"],
}

const sign = [
    {
        path: "/sign",
        component: () => import("@/layouts/blank-layout/Blanklayout"),
        meta: {
          auth: false
        },
        children: [
          {
            name: "SignIn",
            path: "in",
            component: () => import("@/views/sign/signin"),
            meta: {
              auth: false
            }    
          },
          {
            name: "SignUp",
            path: "up",
            component: () => import("@/views/sign/signup"),    
            meta: {
              auth: false
            }
          },
          {
            name: "Recover",
            path: "recover",
            component: () => import("@/views/sign/recover"),
            meta: {
              auth: false
            }
          },
          {
            name: "ChangePassword",
            path: "recover/:token",
            component: () => import("@/views/sign/recover_changepassword"),
            meta: {
              auth: false
            }
          },
          {
            name: "SignCondominium",
            path: "condominium",
            component: () => import("@/views/sign/signcondominium"),
            meta: {
              auth: true,
              permissions: [
                policies["condominium_create"],
                policies["condominium_view"]
              ]
  
            }
          },
          {
            name: "ChooseCondominium",
            path: "choose-condominium",
            component: () => import("@/views/sign/choose_condominium"),
            meta: {
              auth: true,
              permissions: [
                policies["condominium_create"],
                policies["condominium_view"]
              ]
  
            }
          },
          {
            name: "ConfirmEmail",
            path: "confirm",
            component: () => import("@/views/sign/confirm"),
            meta: {
              auth: false
            }
          },
          {
            name: "Invited",
            path: "invited",
            component: () => import("@/views/sign/invited"),
            meta: {
              auth: false
            }
          }
        ]
      }
]

export default sign;