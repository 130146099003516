'strict mode'

import Vue from "vue";

import axios from 'axios';

import {store, mutations} from '@/store';
import utils from "./utils";

const sign_in = (user) => {
    return new Promise((resolve, reject) => {
        axios.post("v1/signin", user)
        .then( response => {
            _set_access_token(response.data.access_token);
            resolve()
        })
        .catch( error => {
            reject(utils.get_error(error));
        });    
    });
}

const sign_up = (user) => {
    user["app_client"] = {
        "client_id": process.env.VUE_APP_NC_CLIENT_ID
    }
    return new Promise((resolve, reject) => {
        axios.post("v1/signup", user)
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const is_authenticated = (condominium_key = null) => {
    _load_access_token();
    return new Promise((resolve, reject) => {
        if(store.access_token == null) {
            reject();
        } else {
            if(store.user && store.user.selected_condominium) {
                condominium_key = store.user.selected_condominium.key
            } else if(condominium_key == null) {
                condominium_key = "_"
            }    
            axios.get(`/v1/me/${condominium_key}`, {
                headers: { Authorization: `Bearer ${store.access_token}` }
            })
            .then(response => {
                _set_user(response.data);
                resolve();
            })
            .catch( error => {
                reject(utils.get_error(error));  
            });        
        }
    });
}

const validate_email = (key) => {
    return new Promise((resolve, reject) => {
        axios.get("v1/signup/confirm?key=" + key)
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
};

const send_confirmation = (user) => {
    user["app_client"] = {
        "client_id": process.env.VUE_APP_NC_CLIENT_ID
    }
    return new Promise((resolve, reject) => {
        axios.post("/v1/signup/send-confirmation", user)
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const recover_password = (user) => {
    user["app_client"] = {
        "client_id": process.env.VUE_APP_NC_CLIENT_ID
    }
    return new Promise((resolve, reject) => {
        axios.post("/v1/signin/recover", user)
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const change_password = (user, token) => {
    user["app_client"] = {
        "client_id": process.env.VUE_APP_NC_CLIENT_ID
    }
    return new Promise((resolve, reject) => {
        axios.post("/v1/signin/recover/" + token, user)
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const logout = () => {
    Vue.$cookies.remove("access_token");
    mutations.set_access_token(null);
    mutations.set_user(null);
}

const accept_invited = (user, key, token, condominium) => {
    user["app_client"] = {
        "client_id": process.env.VUE_APP_NC_CLIENT_ID
    }
    return new Promise((resolve, reject) => {
        axios.post(`/v1/signup/invited/${condominium}?key=${key}&token=${token}`, user)
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const _set_access_token = (access_token) => {
    Vue.$cookies.set("access_token", access_token)
    mutations.set_access_token(access_token);
}

const _load_access_token = () => {
    if(store.access_token == null) {
        const access_token = Vue.$cookies.get("access_token");
        if(access_token != null) {
            _set_access_token(access_token);
        }
    }    
}

const _set_user = (user) => {
    mutations.set_user(user);
}

export default {
    sign_in,
    sign_up,
    is_authenticated,
    validate_email,
    send_confirmation,
    recover_password,
    change_password,
    logout,
    accept_invited
}