
const policies = {
  "tickets_create": ["/api/v1/ticket/ticket/:condominium_id", "POST"],
  "tickets_types":  ["/api/v1/ticket/types/:condominium_id", "GET"],
  "tickets_list": ["/api/v1/ticket/tickets/:condominium_id", "GET"],
  "tickets_delete": ["/api/v1/ticket/ticket/:condominium_id/:id", "DELETE"],
  "tickets_update": ["/api/v1/ticket/ticket/:condominium_id/:id", "PUT"],
  "tickets_read": ["/api/v1/ticket/ticket/:condominium_id/:id", "GET"],
}

const tickets = [
  {    
    path: "/tickets",
    component: () => import("@/layouts/full-layout/Layout"),
    children: [
      {
        name:"TicketsList",
        path: "list",
        component: () => import("@/views/tickets/list"),
        meta: {
          permissions: [
            policies["tickets_list"]
          ]
        }
      },
      {
        name:"TicketsHistoric",
        path: "look/:id",
        component: () => import("@/views/tickets/look"),
        meta: {
          permissions: [
            policies["tickets_update"],
          ]
        }
      },
      {
        name:"TicketsAdd",
        path: ":name",
        component: () => import("@/views/tickets/form"),
        meta: {
          permissions: [
            policies["tickets_create","tickets_types"],
          ]
        }
      },
      {
        name:"TicketsUpdate",
        path: ":name/:id",
        component: () => import("@/views/tickets/form"),
        meta: {
          permissions: [
            policies["tickets_update"],
          ]
        }
      },
    ]
  }
]

export default tickets;