
const policies = {
  
  "contracts_list":   ["/api/v1/supplier/contracts/:condominium_id", "GET"],
  "contracts_create": ["/api/v1/supplier/contract/:condominium_id", "POST"],
  "contracts_update": ["/api/v1/supplier/contract/:condominium_id/:id", "PUT"],
  "contracts_remove": ["/api/v1/supplier/contract/:condominium_id/:id", "DELETE"],
  
}

const contract = [
  {
    path: "/contracts",
    component: () => import("@/layouts/full-layout/Layout"),
    children: [
      {
        name:"ContractsList",
        path: "list",
        component: () => import("@/views/contracts/list"),
        meta: {
          permissions: [
            policies["contracts_list"]
          ]
        }
      },
      {
        name:"ContractsNew",
        path: ":name",
        component: () => import("@/views/contracts/form"),
        meta: {
          permissions: [
            policies["contracts_list"]
          ]
        }
      },
      {
        name:"ContractsUpdate",
        path: ":name/:id",
        component: () => import("@/views/contracts/form"),
        meta: {
          permissions: [
            policies["contracts_list"]
          ]
        }
      },
    ]
  },
]

export default contract;