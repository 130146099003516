'strict mode'

import axios from 'axios';
import utils from './utils';

import {store} from '@/store';

const create = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/financial/order/${store.user.selected_condominium.key}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const list = (page=1, size=10, sort_by="date", sort_order="ASC", params) => {
    let query = ""
    if(params != undefined){
        if(params.month != 0){
            query+="&month="+params.month
        }
        if(params.year != "Todos"){
            query+="&year="+params.year
        }
        if(params.state != 0){
            query+="&state="+params.state
        }
    }
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/financial/orders/${store.user.selected_condominium.key}?page=${page}&size=${size}&sort_by=${sort_by}&sort_order=${sort_order}${query}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const specific = (id) => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/financial/order/${store.user.selected_condominium.key}/${id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const update = (data, id) => {
    return new Promise ((resolve, reject) => {
        axios.put(`/v1/financial/order/${store.user.selected_condominium.key}/${id}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const updateOrderStatus = (id, state) => {
    return new Promise((resolve, reject) => {
        axios.put(`/v1/financial/order/${store.user.selected_condominium.key}/${id}/${state}`, {}, {
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const stateUpdate = (id, state) => {
    return new Promise ((resolve, reject) => {
        axios.put(`/v1/financial/order/${store.user.selected_condominium.key}/${id}/${state}`, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const remove = (id) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/financial/order/${store.user.selected_condominium.key}/${id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` },
            data: id
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const bulkRemove = (ids) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/financial/orders/${store.user.selected_condominium.key}`,{
            headers: { Authorization: `Bearer ${store.access_token}` },
            data:ids
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}


export default {
    create,
    list,
    specific,
    update,
    updateOrderStatus,
    remove,
    bulkRemove,
    stateUpdate
}