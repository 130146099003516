
const policies = {
  "units_list":   ["/api/v1/condominium/units/:condominium_id", "GET"],
  "units_create": ["/api/v1/condominium/unit/:condominium_id", "POST"],
  "units_update": ["/api/v1/condominium/unit/:condominium_id/:id", "PUT"],
  "units_remove": ["/api/v1/condominium/unit/:condominium_id/:id", "DELETE"],
  
  "extension_list": ["/api/v1/condominium/unit_extensions/:condominium_id", "GET"],
  "extension_getSpecific": ["/api/v1/condominium/unit_extension/:condominium_id/:id", "GET"],
  "extension_create": ["/api/v1/condominium/unit_extension/:condominium_id", "POST"],
  "extension_update": ["/api/v1/condominium/unit_extension/:condominium_id/:id", "PUT"],
  "extension_remove": ["/api/v1/condominium/unit_extension/:condominium_id/:id", "DELETE"],
}

const unit = [
  {
    path: "/units",
    component: () => import("@/layouts/full-layout/Layout"),
    children: [
      {
        name:"UnitsList",
        path: "list",
        component: () => import("@/views/units/list"),
        meta: {
          permissions: [
            policies["units_list", "extension_list", "extension_getSpecific", "extension_create", "extension_update", "extension_remove"]
          ]
        }
      },
      {
        name:"UnitsNew",
        path: ":name",
        component: () => import("@/views/units/form"),
        meta: {
          permissions: [
            policies["units_create"]
          ]
        }
      },
      {
        name:"UnitsUpdate",
        path: ":name/:id",
        component: () => import("@/views/units/form"),
        meta: {
          permissions: [
            policies["units_update"]
          ]
        }
      },
    ]
  },
]

export default unit;