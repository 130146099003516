
import numeral from "numeral";
import "numeral/locales/pt-br";

export default {
  install: (app) => {
    numeral.locale('pt-br');
    numeral.register('format', 'square_meter', {
      regexps: {
          format: /(m2)/,
          unformat: /(m2)/
      },
      format: function(value, format, roundingFunction) {
          var space = numeral._.includes(format, ' m2') ? ' ' : '', output;

          // check for space before %
          format = format.replace(/\s?m2/, '');
  
          output = numeral._.numberToFormat(value, format, roundingFunction);
  
          if (numeral._.includes(output, ')')) {
              output = output.split('');
  
              output.splice(-1, 0, space + 'm²');
  
              output = output.join('');
          } else {
              output = output + space + 'm²';
          }
  
          return output;
      },
      unformat: function(string) {
          return numeral._.stringToNumber(string) * 0.01;
      }
    });
    
    app.prototype.$numeral_format = (number, format) => {
      let numeral_obj = numeral(number);
      return numeral_obj.format(format);
    }

    app.prototype.$numeral_square_metter = (number) => {
      let numeral_obj = numeral(number);
      return numeral_obj.format('0,0.000 m2');
    }

  }
}