import Vue from "vue";

export const store = Vue.observable({
  Sidebar_drawer: null,
  Customizer_drawer: false,
  SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
  SidebarBg: "",
  navbarColor: "red darken-4",
  setHorizontalLayout: false, // Horizontal layout

  access_token: null,
  user: null,

});

export const mutations = {
  SET_SIDEBAR_DRAWER(payload) {
    store.Sidebar_drawer = payload;
  },
  SET_CUSTOMIZER_DRAWER(payload) {
    store.Customizer_drawer = payload;
  },
  SET_SIDEBAR_COLOR(payload) {
    store.SidebarColor = payload;
  },
  SET_NAVBAR_COLOR(payload) {
    store.navbarColor = payload;
  },
  SET_LAYOUT(payload) {
    store.setHorizontalLayout = payload;
  },
  set_access_token(access_token) {
    store.access_token = access_token;
  },
  set_user(user){
    store.user = user;
  },
};

export default {
  store,
  mutations
}
