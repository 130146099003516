'strict mode'

import axios from 'axios';
import utils from './utils';

import {store} from '@/store';

const sync = () => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/notifications/${store.user.selected_condominium.key}/sync`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

export default {
    sync
}