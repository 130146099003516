'strict mode'

import axios from 'axios';
import utils from './utils';

import {store} from '@/store';

const create = (condominium) => {
    return new Promise((resolve, reject) => {
        axios.post("/v1/condominium", condominium, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

export default {
    create
}