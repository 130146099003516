'strict mode'

import axios from 'axios';
import utils from './utils';

import {store} from '@/store';

const list = () => {
    return new Promise((resolve, reject) => {
        axios.get(`/v1/users/${store.user.selected_condominium.key}`,  {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const invite = (user) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/user/invite/${store.user.selected_condominium.key}`, user,  {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const refuse = (user) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/v1/user/refuse/${store.user.selected_condominium.key}`,   {
            headers: { Authorization: `Bearer ${store.access_token}` },
            data: user
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

export default {
    list,
    invite,
    refuse
}