
const policies = {
  "reservable_list":   ["/api/v1/condominium/reservables/:condominium_id", "GET"],
  "reservable_create": ["/api/v1/condominium/reservable/:condominium_id/:commonarea_id", "POST"],
  "reservable_update": ["/api/v1/condominium/reservable/:condominium_id/:commonarea_id/:id", "PUT"],
  "reservable_remove": ["/api/v1/condominium/reservable/:condominium_id/:id", "DELETE"],
}

const reservable = [
  {
    path: "/reservable",
    component: () => import("@/layouts/full-layout/Layout"),
    children: [
      {
        name:"ReservableList",
        path: "list",
        component: () => import("@/views/reservable/list"),
        meta: {
          permissions: [
            policies["reservable_list"]
          ]
        }
      },
      {
        name:"ReservableNew",
        path: ":name",
        component: () => import("@/views/reservable/form"),
        meta: {
          permissions: [
            policies["reservable_create"]
          ]
        }
      },
      {
        name:"ReservableUpdate",
        path: ":name/:id",
        component: () => import("@/views/reservable/form"),
        meta: {
          permissions: [
            policies["reservable_update"]
          ]
        }
      },
    ]
  },
]

export default reservable;