
const policies = {
  "receive_create": ["/api/v1/financial/receive/:condominium_id", "POST"],
  "receive_list": ["/api/v1/financial/receives/:condominium_id", "GET"],
  "receive_delete": ["/api/v1/financial/receive/:condominium_id/:id", "DELETE"],
  "receive_update": ["/api/v1/financial/receive/:condominium_id/:id", "PUT"],
  "receive_extract": ["/api/v1/financial/budget/:condominium_id/:id/ladgers", "GET"],
  "summary_get": ["/api/v1/summary/condominium_fee/:condominium_id", "GET"],
  "payOrder_create": ["/api/v1/financial/order/:condominium_id", "POST"],
  "payOrder_list": ["/api/v1/financial/orders/:condominium_id", "GET"],
  "payOrder_specific": ["/api/v1/financial/order/:condominium_id/:id", "GET"],
  "payOrder_delete": ["/api/v1/financial/order/:condominium_id/:id", "DELETE"],
  "payOrder_bulkDelete": ["/api/v1/financial/orders/:condominium_id", "DELETE"],
  "payOrder_update": ["/api/v1/financial/order/:condominium_id/:id", "GET"],
}

const accounts_receivable = [
  {
    path: "/accounts-receivable",
    component: () => import("@/layouts/full-layout/Layout"),
    children: [
      {
        name:"ReceivableList",
        path: "list",
        component: () => import("@/views/accounts-receivable/list"),
        meta: {
          permissions: [
            policies["receive_list","summary_get"]
          ]
        }
      },
      {
        name:"ReceivablePayOrder",
        path: "pay-order",
        component: () => import("@/views/accounts-receivable/payOrder"),
        meta: {
          permissions: [
            policies["receive_list","payOrder_list","payOrder_delete","payOrder_bulkDelete"]
          ]
        }
      },
      {
        name:"NewPaymentOrder",
        path: "payOrderNew",
        component: () => import("@/views/accounts-receivable/payOrderNew"),
        meta: {
          permissions: [
            policies["payOrder_delete","payOrder_bulkDelete","payOrder_create"]
          ]
        }
      },
      {
        name:"ReceivableLot",
        path: ":name",
        component: () => import("@/views/accounts-receivable/form"),
        meta: {
          permissions: [
            policies["receive_create","summary_get"]
          ]
        }
      },
      {
        name:"ReceivableAdd",
        path: ":name",
        component: () => import("@/views/accounts-receivable/form"),
        meta: {
          permissions: [
            policies["receive_create"],
          ]
        }
      },
      {
        name:"ReceivableUpdate",
        path: ":name/:id",
        component: () => import("@/views/accounts-receivable/form"),
        meta: {
          permissions: [
            policies["receive_update","summary_get"],
          ]
        }
      },
    ]
  },
]

export default accounts_receivable;