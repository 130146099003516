'strict mode'

import axios from 'axios';
import utils from './utils';

import {store} from '@/store';

const createPayable = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/financial/pay/${store.user.selected_condominium.key}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}


const createBulk = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/financial/pay/bulk/${store.user.selected_condominium.key}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}


const updateStatus = (id, status) => {
    return new Promise((resolve, reject) => {
        axios.put(`/v1/financial/pay/${store.user.selected_condominium.key}/${id}/${status}`, {}, {
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const updatePayable = (id, data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/v1/financial/pay/${store.user.selected_condominium.key}/${id}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const listPayable = (page=1, size=10, type="P", sort_by="date", sort_order="ASC") => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/financial/pays/${store.user.selected_condominium.key}?page=${page}&size=${size}&type=${type}&sort_by=${sort_by}&sort_order=${sort_order}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const chartActionByYear = (type="R", year=2021) => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/financial/pay/${store.user.selected_condominium.key}/charts/action_by_year?type=${type}&year=${year}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}


const remove = (id) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/financial/pay/${store.user.selected_condominium.key}/${id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

export default {
    createPayable,
    createBulk,
    updateStatus,
    updatePayable,
    listPayable,
    chartActionByYear,
    remove,
}