import Vue from "vue";
import App from "./App.vue";

import "./plugins/base";
import vuetify from "./plugins/vuetify";
import i18n from './plugins/vuei18n'
import './plugins/veevalidate';

import Vuebar from "vuebar";
import VueSkycons from "vue-skycons";
import InstantSearch from "vue-instantsearch";

import axios from 'axios';
import https from 'https';

import router from "./router";
import store from "./store";
import VueCurrencyInput from 'vue-currency-input'

import ncondo_v1 from "@/api/ncondo/v1";

const pluginOptions = {
    globalOptions: {currency: 'BRL', locale: 'pt-br', autoDecimalMode: false,valueRange: {min: 0}, precision: 4, distractionFree: true, allowNegative: false}
}
Vue.use(VueCurrencyInput,pluginOptions)

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter, {
    symbol : 'R$ ',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true,
    avoidEmptyDecimals: undefined,
  })

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import vuenumeral from './plugins/vuenumeral'
Vue.use(vuenumeral)

const httpsAgent = new https.Agent({ rejectUnauthorized: false });
axios.defaults.httpAgent = httpsAgent;
axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://'+process.env.VUE_APP_NC_HOST+'/api';

Vue.use(VueSkycons, {
  color: "#1e88e5",
});
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);

Vue.use(require('vue-cookies'));
Vue.$cookies.config('1d', '', '', true);

function timeout() {
  setTimeout(function () {
      ncondo_v1.notification.sync()
      timeout();
  }, 30000);
}
timeout();

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: (h) => h(App)
}).$mount("#app")
