
const policies = {
  
  "budget_create": ["/api/v1/financial/budget/:condominium_id", "POST"],
  "budget_list": ["/api/v1/financial/budgets/:condominium_id", "GET"],
  "budget_get": ["/api/v1/financial/budget/:condominium_id/:id", "GET"],
  "budget_delete": ["/api/v1/financial/budget/:condominium_id/:id", "DELETE"],
  "budget_update": ["/api/v1/financial/budget/:condominium_id/:id", "PUT"],

}

const budget = [
  {    
    path: "/budget",
    component: () => import("@/layouts/full-layout/Layout"),
    children: [
      {
        name:"BudgetList",
        path: "list",
        component: () => import("@/views/budget/list"),
        meta: {
          permissions: [
            policies["budget_list"]
          ]
        }
      },        
      {
        name:"BudgetAnnual",
        path: "annuals",
        component: () => import("@/views/budget/annual"),
        meta: {
          permissions: [
            policies["budget_list"],
          ]
        }
      },
      {
        name:"BudgetAnnualStatement",
        path: "annual/statement/:id",
        component: () => import("@/views/budget/annual_statement"),
        meta: {
          permissions: [
            policies["budget_list"],
          ]
        }
      },
      {
        name:"BudgetAnnualNew",
        path: "annual/:name",
        component: () => import("@/views/budget/annual_form"),
        meta: {
          permissions: [
            policies["budget_list"],
          ]
        }
      },
      {
        name:"BudgetAnnualUpdate",
        path: "annual/:name/:id",
        component: () => import("@/views/budget/annual_form"),
        meta: {
          permissions: [
            policies["budget_list"],
          ]
        }
      },
      {
        name:"BudgetAdd",
        path: ":name",
        component: () => import("@/views/budget/form"),
        meta: {
          permissions: [
            policies["budget_list"],
          ]
        }
      },
    {
        name:"BudgetUpdate",
        path: ":name/:id",
        component: () => import("@/views/budget/form"),
        meta: {
          permissions: [
            policies["budget_list"],
          ]
        }
      },

    ]
  },
]

export default budget;