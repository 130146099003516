
const policies = {
  "reservation_list":   ["/api/v1/condominium/reservations/:condominium_id", "GET"],
  "reservation_create": ["/api/v1/condominium/reservation/:condominium_id/:reservable_id", "POST"],
  "reservation_createwith": ["/api/v1/condominium/reservation/:condominium_id/:reservable_id/unit/:unit_id", "POST"],
  "reservation_update": ["/api/v1/condominium/reservation/:condominium_id/:id", "PUT"],
  "reservation_remove": ["/api/v1/condominium/reservation/:condominium_id/:id", "DELETE"],
}

const reservation = [
  {
    path: "/book",
    component: () => import("@/layouts/full-layout/Layout"),
    children: [
      {
        name:"BookList",
        path: "list",
        component: () => import("@/views/reservation/list"),
        meta: {
          permissions: [
            policies["reservation_list"]
          ]
        }
      },
      {
        name:"BookNew",
        path: ":name",
        component: () => import("@/views/reservation/form"),
        meta: {
          permissions: [
            policies["reservation_create","reservation_createwith"]
          ]
        }
      },
      {
        name:"BookUpdate",
        path: ":name/:id",
        component: () => import("@/views/reservation/form"),
        meta: {
          permissions: [
            policies["reservation_update"]
          ]
        }
      },
    ]
  },
]

export default reservation;