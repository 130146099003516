'strict mode'

import axios from 'axios';
import utils from './utils';

import {store} from '@/store';

const create = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/condominium/floor/${store.user.selected_condominium.key}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const bulkCreate = (block_id,data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/condominium/floors/${store.user.selected_condominium.key}/${block_id}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const listSpecific = (block_id) => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/condominium/floors/${store.user.selected_condominium.key}?block_id=${block_id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const update = (id, data) => {
    return new Promise ((resolve, reject) => {
        axios.put(`/v1/condominium/floor/${store.user.selected_condominium.key}/${id}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const remove = (data) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/condominium/floor/${store.user.selected_condominium.key}/${data}`,{
            headers: { Authorization: `Bearer ${store.access_token}` },
            data: data
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}


export default {
    create,
    bulkCreate,
    listSpecific,
    update,
    remove
}