
const policies = {
  
  "authorization_list": ["/api/v1/authorizations/:condominium_id", "GET"],
  
}

const icons = [
  {
    path: "/icons",
    component: () => import("@/layouts/full-layout/Layout"),
    children: [
      {
        name:"fontawesomeicons",
        path: "fai",
        component: () => import("@/views/icons/FontAwesomeIcons"),
        meta: {
          permissions: [
            policies["authorization_list"]
          ]
        }
      },
      {
        name:"materialdesignicons",
        path: "mdi",
        component: () => import("@/views/icons/MaterialIcons"),
        meta: {
          permissions: [
            policies["authorization_list"]
          ]
        }
      },
      {
        name:"simplelineicons",
        path: "sli",
        component: () => import("@/views/icons/SimpleLineIcons"),
        meta: {
          permissions: [
            policies["authorization_list"]
          ]
        }
      },
      {
        name:"themifyicons",
        path: "tmi",
        component: () => import("@/views/icons/ThemifyIcons"),
        meta: {
          permissions: [
            policies["authorization_list"]
          ]
        }
      },
    ]
  },
]

export default icons;