'strict mode'

import axios from 'axios';
import utils from './utils';

import {store} from '@/store';

const create = (data, areaid) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/condominium/reservation/${store.user.selected_condominium.key}/${areaid}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const createWith = (data, areaid, unitid) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/condominium/reservation/${store.user.selected_condominium.key}/${areaid}/unit/${unitid}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const list = () => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/condominium/reservations/${store.user.selected_condominium.key}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const possibleAreas = () => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/condominium/reservables/${store.user.selected_condominium.key}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const update = (data, id) => {
    return new Promise ((resolve, reject) => {
        axios.put(`/v1/condominium/reservation/${store.user.selected_condominium.key}/${id}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const remove = (id) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/condominium/reservation/${store.user.selected_condominium.key}/${id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const removeForce = (id) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/condominium/reservation/${store.user.selected_condominium.key}/${id}/force`,{
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const removeWithTax = (id) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/condominium/reservation/${store.user.selected_condominium.key}/${id}/accept-tax`,{
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}



export default {
    create,
    createWith,
    list,
    possibleAreas,
    update,
    remove,
    removeForce,
    removeWithTax,
}