'strict mode'

import axios from 'axios';
import utils from './utils';

import {store} from '@/store';

const list = (page=1, size=10, sort_by="created", sort_order="ASC",created="",updated="",status="",step="",type="") => {

    let all = ""
    if(store.user.authorizations.some( auth => auth.method == "GET" && auth.resource == "/api/v1/ticket/tickets/:condominium_id/all" )){
        all = "/all"
    }

    let params = ""
    created != "" ? params += "&created="+created : "";
    updated != "" ? params += "&updated="+updated : "";
    status != "" ? params += "&status="+status : "";
    step != "" ? params += "&step="+step : "";
    type != "" ? params += "&type="+type : "";
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/ticket/tickets/${store.user.selected_condominium.key}${all}?sort_by=${sort_by}&sort_order=${sort_order}&page=${page}&size=${size}${params}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const listTypes = () => {
  return new Promise((resolve, reject) => {
      axios.get(`/v1/ticket/types/${store.user.selected_condominium.key}`, {
          headers: { Authorization: `Bearer ${store.access_token}` }
      })
      .then( response => {
          resolve(response.data);
      })
      .catch( error => {
        reject(utils.get_error(error));
      });
  });
}
const statuses = () => {
    return new Promise((resolve, reject) => {
        axios.get(`/v1/ticket/statuses/${store.user.selected_condominium.key}`, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
  }
const listSteps = () => {
  return new Promise((resolve, reject) => {
      axios.get(`/v1/ticket/steps/${store.user.selected_condominium.key}`, {
          headers: { Authorization: `Bearer ${store.access_token}` }
      })
      .then( response => {
          resolve(response.data);
      })
      .catch( error => {
        reject(utils.get_error(error));
      });
  });
}
const listWorkflow = () => {
  return new Promise((resolve, reject) => {
      axios.get(`/v1/ticket/workflow/${store.user.selected_condominium.key}`, {
          headers: { Authorization: `Bearer ${store.access_token}` }
      })
      .then( response => {
          resolve(response.data);
      })
      .catch( error => {
            reject(utils.get_error(error));
      });
  });
}
const listStepTo = () => {
  return new Promise((resolve, reject) => {
      axios.get(`/v1/ticket/step-to-status/${store.user.selected_condominium.key}`, {
          headers: { Authorization: `Bearer ${store.access_token}` }
      })
      .then( response => {
          resolve(response.data);
      })
      .catch( error => {
        reject(utils.get_error(error));
      });
  });
}
const read = (id) => {
    let force = ""
    if(store.user.authorizations.some(auth=> auth.method == "GET" && auth.resource == "/api/v1/ticket/ticket/:condominium_id/:id/force")){
        force = "/force"
    }
  return new Promise((resolve, reject) => {
      axios.get(`/v1/ticket/ticket/${store.user.selected_condominium.key}/${id}${force}`, {
          headers: { Authorization: `Bearer ${store.access_token}` }
      })
      .then( response => {
          resolve(response.data);
      })
      .catch( error => {
        reject(utils.get_error(error));
      });
  });
}
const create = (data) => {
  return new Promise((resolve, reject) => {
      axios.post(`/v1/ticket/ticket/${store.user.selected_condominium.key}`,data, {
          headers: { Authorization: `Bearer ${store.access_token}` }
      })
      .then( response => {
          resolve(response.data);
      })
      .catch( error => {
        reject(utils.get_error(error));
      });
  });
}
const update = (id, data) => {
    let force = ""
    if(store.user.authorizations.some(auth=>auth.resource == "/api/v1/ticket/ticket/:condominium_id/:id/force")){
        force = "/force"
    }
  return new Promise((resolve, reject) => {
      axios.put(`/v1/ticket/ticket/${store.user.selected_condominium.key}/${id}${force}`, data, {
          headers: { Authorization: `Bearer ${store.access_token}` },
      })
      .then( response => {
          resolve(response);
      })
      .catch( error => {
        reject(utils.get_error(error));
      });
  });
}
const updateForce = (id, data) => {
  return new Promise((resolve, reject) => {
      axios.put(`/v1/ticket/ticket/${store.user.selected_condominium.key}/${id}/force`, data, {
          headers: { Authorization: `Bearer ${store.access_token}` },
      })
      .then( response => {
          resolve(response);
      })
      .catch( error => {
        reject(utils.get_error(error));
      });
  });
}
const comment = (id,data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/v1/ticket/comment/${store.user.selected_condominium.key}/${id}/`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` },
            data : data
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
  }
const alter = (id, step) => {
  return new Promise((resolve, reject) => {
      axios.put(`/v1/ticket/ticket/${store.user.selected_condominium.key}/${id}/${step}`, "", {
          headers: { Authorization: `Bearer ${store.access_token}` },
      })
      .then( response => {
          resolve(response);
      })
      .catch( error => {
        reject(utils.get_error(error));
      });
  });
}
const remove = (id) => {
    let force = ""
    if(store.user.authorizations.some(auth=> auth.method == "DELETE" && auth.resource == "/api/v1/ticket/ticket/:condominium_id/:id/force")){
        force = "/force"
    }
  return new Promise ((resolve, reject) => {
      axios.delete(`/v1/ticket/ticket/${store.user.selected_condominium.key}/${id}${force}`,{
          headers: { Authorization: `Bearer ${store.access_token}` }
      })
      .then( response => {
          resolve(response.data);
      })
      .catch( error => {
        reject(utils.get_error(error));
      });
  });
} 
const attach = (file, id) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/ticket/ticket/${store.user.selected_condominium.key}/${id}/attach`, file, {
            headers: { Authorization: `Bearer ${store.access_token}`, "Content-Type": "multipart/form-data" }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const unattach = (fileinfo_id, id) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/ticket/ticket/${store.user.selected_condominium.key}/${id}/unattach/${fileinfo_id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}
const seeAttach = (fileinfo_id, id) => {
    return new Promise((resolve, reject) => {
        axios.get(`/v1/ticket/ticket/${store.user.selected_condominium.key}/${id}/attached/${fileinfo_id}`, {
            headers: { Authorization: `Bearer ${store.access_token}` },
            responseType: "blob"
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
  }



export default {
list,
statuses,
listTypes,
listSteps,
listStepTo,
listWorkflow,
read,
create,
update,
updateForce,
comment,
alter,
remove,
attach,
unattach,
seeAttach,
}