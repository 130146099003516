'strict mode'

import axios from 'axios';
import {store} from '@/store';
import utils from './utils';

const create = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/administration/approval/${store.user.selected_condominium.key}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const list = () => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/administration/approvals/${store.user.selected_condominium.key}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const listTypes = () => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/administration/approval_types/${store.user.selected_condominium.key}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const update = (data, id) => {
    return new Promise ((resolve, reject) => {
        axios.put(`/v1/administration/approval/${store.user.selected_condominium.key}/${id}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const associate = (id, key, data) => {
    return new Promise ((resolve, reject) => {
        axios.post(`/v1/administration/approval/${store.user.selected_condominium.key}/${id}/user/${key}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const disassociate = (id, key) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/administration/approval/${store.user.selected_condominium.key}/${id}/user/${key}`, {
            headers: { Authorization: `Bearer ${store.access_token}` },
            data: ''
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const remove = (id) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/administration/approval/${store.user.selected_condominium.key}/${id}`,{
            headers: { Authorization: `Bearer ${store.access_token}` },
            data: id
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

export default {
    create,
    list,
    listTypes,
    update,
    associate,
    disassociate,
    remove
}