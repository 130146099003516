'strict mode'

import axios from 'axios';
import utils from './utils';

import {store} from '@/store';


const getFromYear = (year) => {
    return new Promise ((resolve, reject) => {
        let q = ""
        if(year != undefined ){
            q =`?year=${year}`
        }
        axios.get(`/v1/summary/condominium_fee/${store.user.selected_condominium.key}${q}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}



export default {

    getFromYear

}