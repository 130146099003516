
const policies = {
  "create": ["/api/v1/financial/purchase/:condominium_id", "POST"],
  "list": ["/api/v1/financial/purchases/:condominium_id", "GET"],
  "get": ["/api/v1/financial/purchase/:condominium_id/:id", "GET"],
  "update": ["/api/v1/financial/purchase/:condominium_id/:id", "PUT"],
  "delete": ["/api/v1/financial/purchase/:condominium_id/:id", "DELETE"],
  "approval": ["/api/v1/financial/purchase/:condominium_id/:id/approval/:action", "PUT"],
  "actionWithQuote": ["/api/v1/financial/purchase/:condominium_id/:id/approval/:action/:quote_id", "PUT"],
  "actionWithoutQuote": ["/api/v1/financial/purchase/:condominium_id/:id/approval/:action", "PUT"],
  "purchaseOrder":["/v1/financial/purchase/:condominium_id/:id/order/:state", "PUT"]
}

const purchase = [
  {
    path: "/purchase",
    component: () => import("@/layouts/full-layout/Layout"),
    children: [
      {
        name:"purchaseList",
        path: "list",
        component: () => import("@/views/purchase/list"),
        meta: {
          permissions: [
            policies["list", "actionWithQuote"]
          ]
        }
      },
      {
        name:"purchasetNew",
        path: ":name",
        component: () => import("@/views/purchase/form"),
        meta: {
          permissions: [
            policies["create"]
          ]
        }
      },
      {
        name:"purchaseUpdate",
        path: ":name/:id",
        component: () => import("@/views/purchase/form"),
        meta: {
          permissions: [
            policies["update"]
          ]
        }
      },
      {
        name:"purchaseStatement",
        path: "user/statement/:id",
        component: () => import("@/views/purchase/statement"),
        meta: {
          permissions: [
            policies["get","actionWithoutQuote","actionWithQuote"]
          ]
        }
      },
    ]
  },
]

export default purchase;