
const policies = {
  "suppliers_list":   ["/api/v1/supplier/suppliers/:condominium_id", "GET"],
  "suppliers_create": ["/api/v1/supplier/supplier/:condominium_id", "POST"],
  "suppliers_update": ["/api/v1/supplier/supplier/:condominium_id/:id", "PUT"],
  "suppliers_remove": ["/api/v1/supplier/supplier/:condominium_id/:id", "DELETE"],
}

const supplier = [
  {
    path: "/suppliers",
    component: () => import("@/layouts/full-layout/Layout"),
    children: [
      {
        name:"SuppliersList",
        path: "list",
        component: () => import("@/views/suppliers/list"),
        meta: {
          permissions: [
            policies["suppliers_list"]
          ]
        }
      },
      {
        name:"SuppliersNew",
        path: ":name",
        component: () => import("@/views/suppliers/form"),
        meta: {
          permissions: [
            policies["suppliers_list"]
          ]
        }
      },
      {
        name:"SuppliersUpdate",
        path: ":name/:id",
        component: () => import("@/views/suppliers/form"),
        meta: {
          permissions: [
            policies["suppliers_list"]
          ]
        }
      },
    ]
  },
]

export default supplier;