
const policies = {
  "commons_list":   ["/api/v1/condominium/commonareas/:condominium_id", "GET"],
  "commons_create": ["/api/v1/condominium/commonarea/:condominium_id", "POST"],
  "commons_update": ["/api/v1/condominium/commonarea/:condominium_id/:id", "PUT"],
  "commons_remove": ["/api/v1/condominium/commonarea/:condominium_id/:id", "DELETE"],
}

const commonAreas = [
  {
    path: "/commons",
    component: () => import("@/layouts/full-layout/Layout"),
    children: [
      {
        name:"CommonsList",
        path: "list",
        component: () => import("@/views/commonAreas/list"),
        meta: {
          permissions: [
            policies["commons_list"]
          ]
        }
      },
      {
        name:"CommonsNew",
        path: ":name",
        component: () => import("@/views/commonAreas/form"),
        meta: {
          permissions: [
            policies["commons_create"]
          ]
        }
      },
      {
        name:"CommonsUpdate",
        path: ":name/:id",
        component: () => import("@/views/commonAreas/form"),
        meta: {
          permissions: [
            policies["commons_update"]
          ]
        }
      },
    ]
  },
]

export default commonAreas;