'strict mode'

import axios from 'axios';
import utils from './utils';

import {store} from '@/store';

const create = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/v1/condominium/unit_extension/${store.user.selected_condominium.key}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const list = () => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/condominium/unit_extensions/${store.user.selected_condominium.key}`,{
            headers: { Authorization: `Bearer ${store.access_token}` }
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const getExtensionsFrom = (id) => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/condominium/unit_extensions/${store.user.selected_condominium.key}?unit_id=${id}`, {
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const getSpecific = (id) => {
    return new Promise ((resolve, reject) => {
        axios.get(`/v1/condominium/unit_extension/${store.user.selected_condominium.key}/${id}`, {
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const update = (data, id) => {
    return new Promise ((resolve, reject) => {
        axios.put(`/v1/condominium/unit_extension/${store.user.selected_condominium.key}/${id}`, data, {
            headers: { Authorization: `Bearer ${store.access_token}` },
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

const remove = (data) => {
    return new Promise ((resolve, reject) => {
        axios.delete(`/v1/condominium/unit_extension/${store.user.selected_condominium.key}/${data}`,{
            headers: { Authorization: `Bearer ${store.access_token}` },
            data: data
        })
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(utils.get_error(error));
        });
    });
}

export default {
    create,
    list,
    getExtensionsFrom,
    getSpecific,
    update,
    remove
}